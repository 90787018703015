* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

@media (min-width: 1800px) {
  html {
    font-size: 113%;
  }
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fb;
}

a {
  text-decoration: inherit;
  color: inherit;
}

#root {
  min-height: 100%;
}
